import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import BEM from '../../../../common/bem';
import { Scrollbars, DotsIndicator, AccessibleList } from '../../../../common/components';
import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';
import { Entity } from '../../../../types';
import SearchParityResults from './SearchParityResults';

import styles from './SearchParityResults.module.css';
import { useAppSelector } from 'redux-stores';
const classes = BEM.with('SearchSidebar');

type MobxProps = {
  _scrollContinuation: number;
  isProviderNetwork: boolean;
  input: string;
  isSearching: boolean;
  scrollSearch: () => void;
  searchResults: { entity: Entity; id: string; organizationId: string }[];
  searchErrorOccurred: boolean;
  noResults: boolean;
};

type SearchSidebarProps = {};

const SCROLL_RATIO = 0.6;

const SearchSidebar = ({
  noResults,
  isProviderNetwork,
  input,
  isSearching,
  scrollSearch,
  searchResults,
  searchErrorOccurred,
}: SearchSidebarProps & MobxProps) => {
  const scrollbarsRef = useRef<Scrollbars>(null);

  const [loadingDotsInTheBottomOfList, setLoadingDotsInTheBottomOfList] = useState(false);

  const throttledScrollSearch = debounce(() => {
    setLoadingDotsInTheBottomOfList(true);
    scrollSearch();
  }, 800);

  const handleScroll = (event: React.ChangeEvent<HTMLElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = event.target;
    if (clientHeight / (scrollHeight - scrollTop) > SCROLL_RATIO) {
      throttledScrollSearch();
    }
  };

  useEffect(
    function handleFreshQuery() {
      scrollbarsRef.current?.scrollTop(0);
      setLoadingDotsInTheBottomOfList(false);
    },
    [input]
  );

  const _renderResults = () => {
    let specialCase;
    const { accessibilityMode } = useAppSelector(({ ui }) => ({
      accessibilityMode: ui.accessibilityMode,
    }));

    if (!input) {
      specialCase = <div className={classes('blank-text')}>Begin typing to search</div>;
    } else if (isSearching && !loadingDotsInTheBottomOfList) {
      specialCase = <DotsIndicator className={classes('dots')} size={13} speed={0.5} />;
    } else if (searchErrorOccurred) {
      specialCase = (
        <div className={classes('blank-text')}>
          Search unavailable, <br />
          please try again
        </div>
      );
    } else if (noResults) {
      specialCase = <div className={classes('blank-text')}>No results found</div>;
    }

    if (specialCase) {
      return (
        <div className={classes('flex-absolute-center', { loadingDotsInTheBottomOfList })}>
          {specialCase}
        </div>
      );
    } else {
      return (
        <Scrollbars ref={scrollbarsRef} onScroll={handleScroll}>
          <AccessibleList
            focusableClasses={[`.${styles.item}`]}
            focusableChildrenClasses={['.tc-RoundButton__button']}
            accessibilityMode={accessibilityMode}
            setStartElementOnChange={true}
          >
            <SearchParityResults
              query={input}
              results={searchResults.map((e) => e.entity)}
              network={isProviderNetwork ? 'provider' : 'patient'}
              showPresenceIndicator={true}
              presenceSize={'small'}
              typeOfUser={'searchSideBarUser'}
            />
            {isSearching && loadingDotsInTheBottomOfList && (
              <div style={{ backgroundColor: 'var(--neutral-100)' }}>
                <DotsIndicator className={classes('dots')} size={13} speed={0.5} />
              </div>
            )}
          </AccessibleList>
        </Scrollbars>
      );
    }
  };

  return <div className={classes()}>{_renderResults()}</div>;
};

export default mobxInjectSelect<SearchSidebarProps, MobxProps>({
  networkStore: ['isProviderNetwork'],
  searchSidebarStore: [
    '_scrollContinuation',
    'input',
    'isSearching',
    'searchErrorOccurred',
    'scrollSearch',
    'searchResults',
    'noResults',
  ],
})(SearchSidebar);
